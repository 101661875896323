(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/nav-menu/assets/javascripts/poker-nav-menu.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/nav-menu/assets/javascripts/poker-nav-menu.js');
"use strict";


const {
  Link,
  useLocation
} = ReactRouterDOM;
const {
  ItemsMenu,
  LinkItem,
  ItemsMenuIcon
} = svs.ui.ReactItemsMenu;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const PokerNavMenu = _ref => {
  let {
    routes
  } = _ref;
  const location = useLocation();
  const isSmallScreen = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const activeRoutes = routes.filter(route => route.active);
  return React.createElement(ItemsMenu, {
    branding: "poker-react",
    centered: true,
    className: "poker-nav",
    compact: isSmallScreen
  }, activeRoutes.map(route => {
    const selected = location.pathname === "/".concat(route.path) || location.pathname === '/' && route.path === 'aktuellt';
    return React.createElement(LinkItem, {
      Component: Link,
      "data-testid": "poker-route",
      key: route.path,
      label: isSmallScreen && route.shortLabel || route.label,
      selected: selected,
      to: route.path
    }, Boolean(route.icon) && isSmallScreen && React.createElement(ItemsMenuIcon, {
      icon: route.icon,
      position: "top",
      size: "200"
    }));
  }));
};
setGlobal('svs.poker_react.components.PokerNavMenu', PokerNavMenu);

 })(window);